import { Link } from "gatsby";
import React from "react";
import { menu } from "../constants/menu";
import { socialLinks } from "../constants/socialLinks";
import { FooterStyles } from "../styles/FooterStyles";
import Logo from "./Logo";
import ParagraphText from "./typography/ParagraphText";

function Footer() {
  return (
    <FooterStyles>
      <div className="container">
        <ParagraphText className="footer__text">
          Technology is best when it brings people together.
        </ParagraphText>
        <ul className="footer__menuList">
          {menu.map((item) => (
            <li key={item.path}>
              <Link to={item.path}>{item.title}</Link>
            </li>
          ))}
        </ul>
        <ul className="footer__socialList">
          {socialLinks.map((item) => (
            <li key={item.name}>
              <a href={item.url}>{item.icon}</a>
            </li>
          ))}
        </ul>
        <ParagraphText className="copyright">
          <a href="https://swapso.io" target="_blank" rel="noopener noreferrer">
            © Swapso.io {new Date().getFullYear()} | All rights reserved
          </a>
        </ParagraphText>
      </div>
    </FooterStyles>
  );
}

export default Footer;
