import React, { useEffect } from "react";
import Header from "./Header";
import GlobalStyles from "../styles/GlobalStyles";
import "normalize.css/normalize.css";
import Footer from "./Footer";
import Search from "./search/SearchModal";
import { SearchModalContextProvider } from "../contexts/searchModalContext";
import { Analytics } from "@vercel/analytics/react";

function Layout({ children }) {
  const loadGoogleAdSenseScript = () => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1343366995157756";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    loadGoogleAdSenseScript();
  }, []);

  const loadGoogleTagScript = () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-D5BSRF6SBV";
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-D5BSRF6SBV");
  };

  useEffect(() => {
    loadGoogleTagScript();
  }, []);

  return (
    <SearchModalContextProvider>
      <GlobalStyles />
      <Search />
      <Header />
      <main>{children}</main>
      <Footer />
      <Analytics />
    </SearchModalContextProvider>
  );
}

export default Layout;
